import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Badge,
  Card,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RateReviewIcon from '@mui/icons-material/RateReviewOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import StatusIndicator from '../../../components/styledComponents/StatusIndicator';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import homeStyles from '../homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';

import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { ICompany } from '../../../interfaces/companies';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { thousands } from '../../../utils/utils';
import { IProduct } from '../../../interfaces/product';
import { getProductImage } from './utils/getProductImage';
import { toAbsoluteUrl } from '../../../../_base';
import { useGetFavorites } from './hooks/useGetFavories';
import { useChangeFavorite } from './hooks/useChangeMyFavorite';
import { useStylesProductFavorites } from './hooks/useStyles';
import { useDefineUserRole } from '../../../hooks';

const ProductFavorites: React.FC<TPropsFromRedux> = ({
  me,
  fetchMe,
  loadingMe,
  successMe,
  clearMe,
  clearProducts,

  addProductLoading,
  addProductSuccess,
  addProductError,
  addProductToCart,
  cartCompanyId,
  isAdmin,
  clearParameters,
}) => {
  const homeClasses = homeStyles();
  const classes1 = useStylesProductFavorites();
  const { classes } = classes1;
  const intl = useIntl();
  const navigate = useNavigate();
  const [fetch, loading, products] = useGetFavorites();
  const [changeFavorite, loadingChange, successChange] = useChangeFavorite();
  const [cartProductId, setCartProductId] = useState<number>(-1);
  const [isCartAlertOpen, setCartAlertOpen] = useState(false);
  const [userCompany, setUserCompany] = useState<null | ICompany>();
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  useEffect(() => {
    fetchMe();
    clearParameters();
    return () => {
      clearMe();
      clearProducts();
    };
  }, []);

  useEffect(() => {
    if (me && !userCompany) {
      setUserCompany(me.company);
    }
  }, [successMe]);

  useLayoutEffect(() => {
    me && fetch();
  }, [me]);

  useLayoutEffect(() => {
    successChange && fetch();
  }, [successChange]);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      setCartAlertOpen(false);
      setCartProductId(-1);
    },
  });

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS' }),
  });
  setLayoutFooter({ show: true });

  const editAction = useCallback(
    (item: IProduct, isReviews?: boolean) => {
      navigate(`/products/edit/${item.id}`, { state: { isReviews } });
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: IProduct) => {
      navigate(`/viewproduct/${item.id}`);
    },
    [navigate]
  );

  const handleAddToCart = useCallback(
    (item: IProduct) => {
      if (!item.id) return;

      const isTheSameCompany = cartCompanyId === item.company?.id;

      if (isTheSameCompany || !cartCompanyId) {
        addProductToCart({ product_id: item.id, count: 1 });
      } else {
        setCartProductId(item.id);
        setCartAlertOpen(true);
      }
    },
    [cartCompanyId]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading) {
      addProductToCart({
        product_id: cartProductId,
        count: 1,
        newCart: true,
      });
    }
  }, [cartProductId, addProductToCart]);

  if (!me || loadingMe || !products || loading) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => addCartProductAction()}
      />

      <Row>
        <Col>
          {!products || !products.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        {me.is_admin && (
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.ID' })}
                          </SHeadTableCell>
                        )}
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.CATEGORY.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
                        </SHeadTableCell>
                        {!isBuyer && (
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIVE' })}
                          </SHeadTableCell>
                        )}
                        {me.is_admin && (
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'MENU.COMPANY' })}
                          </SHeadTableCell>
                        )}
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.ACTIONS' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products?.map((item: IProduct) => (
                        <TableRow key={item.id}>
                          {me.is_admin && (
                            <TableCell component='th' scope='row'>
                              {item.id || '-'}
                            </TableCell>
                          )}
                          <TableCell>
                            <img
                              src={getProductImage(item)}
                              className={classes.img}
                              alt=' '
                              onError={(e: any) => {
                                e.target.src = toAbsoluteUrl('/images/placeholder.png');
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Link
                              component={RouterLink}
                              to={`/viewproduct/${item.id}`}
                              className={classes.buttons}
                            >
                              {item.name || '-'}
                            </Link>
                          </TableCell>
                          <TableCell>{item.category?.name || '-'}</TableCell>
                          <TableCell>
                            {item.price ? thousands(String(item.price)) : '-'}
                          </TableCell>
                          {!isBuyer && (
                            <TableCell>
                              <StatusIndicator isActive={item.active} />
                            </TableCell>
                          )}
                          {me.is_admin && (
                            <TableCell>
                              {item.company ? (
                                <Link
                                  component={RouterLink}
                                  to={`/companies/edit/${item.company?.id}`}
                                  className={classes.buttons}
                                >
                                  {item.company.name}
                                </Link>
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          )}
                          <TableCell>
                            <div>
                              {!isVendor && !isAdmin && (
                                <Tooltip
                                  title={intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })}
                                >
                                  <IconButton
                                    disabled={addProductLoading}
                                    onClick={() => handleAddToCart(item)}
                                  >
                                    <AddShoppingCartIcon color='primary' />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title={intl.formatMessage({ id: 'PRODUCT.VIEW' })}>
                                <IconButton onClick={() => viewAction(item)}>
                                  <VisibilityIcon color='primary' />
                                </IconButton>
                              </Tooltip>
                              {!isBuyer && (
                                <>
                                  <Tooltip
                                    title={intl.formatMessage({
                                      id: 'PRODUCT.EDIT',
                                    })}
                                  >
                                    <IconButton onClick={() => editAction(item)}>
                                      <EditIcon color='primary' />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title={intl.formatMessage({
                                      id: 'PRODUCT.ADD.REVIEW',
                                    })}
                                  >
                                    <IconButton onClick={() => editAction(item, true)}>
                                      <Badge
                                        badgeContent={item.review_count || 0}
                                        color='primary'
                                      >
                                        <RateReviewIcon color='primary' />
                                      </Badge>
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'PRODUCT.DELETE.FAVORITE',
                                })}
                              >
                                <IconButton
                                  onClick={() => {
                                    changeFavorite(item.id, true);
                                  }}
                                  disabled={loadingChange}
                                >
                                  <DeleteIcon color='error' />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,
    successMe: state.profile.success,

    addProductLoading: state.cart.addProductLoading,
    addProductSuccess: state.cart.addProductSuccess,
    addProductError: state.cart.addProductError,
    cartCompanyId: state.cart.companyId,
    isAdmin: state.auth.user?.is_admin,
  }),
  {
    clearProducts: productActions.clearProducts,
    clearParameters: productTypesActions.clearParams,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    addProductToCart: cartActions.addProductRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductFavorites);
