import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import { useFormatMessage } from '../../../../hooks';
import { IProduct } from '../../../../interfaces/product';
import { ICategoryItem } from '../../categories/interfaces';
import { actions as productActions } from '../../../../store/ducks/product.duck';
import { IUser } from '../../../../interfaces/user';
import { ITypeParameter } from '../../../../interfaces/productType';

const findCategory = (item: ICategoryItem, id: string, categories: any[]): any => {
  if (!item.children) return categories;
  const category = item.children?.find(i => i.id?.toString() === id);
  if (category) {
    return [...categories, category];
  }
  for (const i of item.children) {
    if (i.children) {
      const newCategories = findCategory(i, id, categories);
      if (newCategories && newCategories.length > 0)
        return [...categories, i, ...newCategories];
    }
  }
  return categories;
};

const getInitValues = (
  locale: string,
  product?: IProduct,
  productCompany?: { id?: number; name?: string },
  parentCategoryId?: string,
  categories?: ICategoryItem[],
  // isFavorite?: boolean
) => {
  const initValues: { [key: string]: any } = {
    name: product?.translations?.[locale]?.name || product?.name || '',
    price: product?.price || '',
    active: product?.active || false,
    mainCategoryId: '',
    categoryId_1: '',
    files: [],
    // если нужно что бы продукт всегда был одного типа, можно поставить здесь ид этого типа
    productTypeId: product?.product_type?.id,

    companyId: productCompany?.id,
    companyName: productCompany?.name || product?.company?.brand || '',
    description: product?.description || null,
    expiration_date: product?.stock_info?.expiration_date,
    length: product?.stock_info?.length,
    width: product?.stock_info?.width,
    height: product?.stock_info?.height,
    weight: product?.stock_info?.weight,
    barcode: product?.stock_info?.barcode,
    favorite: product?.favorite || false,
    stockQuantity: product?.stock_quantity,
    translations: product?.translations || {},
    unit: product ? product.unit : '',
  };

  if (categories) {
    const id = product?.category?.id || parentCategoryId;
    let tree: ICategoryItem[] = [];
    if (id) {
      categories.forEach(item => {
        const newCategories = findCategory(item, id.toString(), []);
        if (newCategories && newCategories.length > 0) {
          tree = [item, ...newCategories];
        }
      });
      tree.forEach((item, index) => {
        if (index === 0) {
          initValues.mainCategoryId = item.id;
        } else {
          initValues[`categoryId_${index}`] = item.id;
        }
      });
    }
  }

  if (product?.parameter_values) {
    product.parameter_values.forEach(item => {
      const value = product.translations?.[locale]?.[`parameter${item.id}`] || item.value;
      initValues[`parameter${item.parameter_id}`] = value;
    });
  }
  return initValues;
};

export const useFormikProductForm = (
  paramLanguage: string,
  fetchTypeId: number | undefined,
  product: IProduct | undefined,
  productCompany: { id?: number; brand?: string } | undefined,
  parentCategoryId: string | undefined,
  typeParams: any,
  images: File[],
  id: string,
  categories?: ICategoryItem[],
  me?: IUser
) => {
  const dispatch = useDispatch();
  const fm = useFormatMessage();
  const intl = useIntl();

  const [descEditorState, setDescEditorState] = useState<any>(null);
  const [descEditorStateTranslation, setDescEditorStateTranslation] = useState<any>(null);

  const validationSchema = useMemo(() => {
    const fields: any = {};
    const requiredParam: ITypeParameter[] = typeParams?.filter(
      (param: ITypeParameter) => !!param.required
    );
    requiredParam?.forEach(param => {
      fields[`parameter${param.id}`] =
        param.type === 'multiple_enum'
          ? Yup.array().min(1, fm('AUTH.VALIDATION.REQUIRED_FIELD'))
          : Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).trim();
    });
    return Yup.object().shape({
      ...fields,
      name: Yup.string().required(fm('PRODUCT.REQUIRED.NAME')).trim(),
      price: Yup.string().required(fm('PRODUCT.REQUIRED.PRICE')).trim(),
      mainCategoryId: Yup.string().required(fm('PRODUCT.REQUIRED.CATEGORY')).trim(),
      categoryId_1: Yup.string(),
      categoryId_2: Yup.string(),
      categoryId_3: Yup.string(),
      categoryId_4: Yup.string(),
      categoryId_5: Yup.string(),
      categoryId: Yup.string().when(
        ['categoryId_1', 'categoryId_2', 'categoryId_3', 'categoryId_4', 'categoryId_5'],
        {
          is: (
            categoryId_1?: string,
            categoryId_2?: string,
            categoryId_3?: string,
            categoryId_4?: string,
            categoryId_5?: string
          ) => {
            if (categoryId_1 || categoryId_2 || categoryId_3 || categoryId_4 || categoryId_5) {
              return false;
            }
            return true;
          },
          then: schema => schema.required(fm('PRODUCT.REQUIRED.CATEGORY')),
        }
      ),
      // barcode: Yup.string().required(fm('PRODUCT.REQUIRED.BARCODE')).trim().nullable(),
      // weight: Yup.string().required(fm('PRODUCT.REQUIRED.WEIGHT')).trim().nullable(),
    });
  }, [typeParams]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(
      intl.locale,
      product,
      productCompany,
      parentCategoryId,
      categories
    ),
    validationSchema,
    onSubmit: submitValues => {
      const data = new FormData();
      submitValues.files.forEach((file: any) => data.append('photo[]', file));
      const translations: { [key: string]: any } = {
        [intl.locale]: {},
        [paramLanguage]: {},
        ...submitValues.translations,
      };
      if (descEditorStateTranslation) {
        translations[paramLanguage].description = descEditorStateTranslation.getData() || '';
      }
      translations[intl.locale].name = submitValues.name;
      data.append('unit', submitValues.unit || '');
      data.append('name', submitValues.name);
      data.append('price', submitValues.price.toString());
      data.append('active', submitValues.active ? '1' : '0');
      me?.is_admin && data.append('favorite', submitValues.favorite ? 'true' : 'false');

      if (descEditorState) {
        translations[intl.locale].description = descEditorState.getData() || '';
        data.append('description', descEditorState.getData() || '');
      }
      data.append('translations', JSON.stringify(translations));

      if (submitValues.productTypeId) {
        data.append('product_type_id', String(submitValues.productTypeId));
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < 6; i++) {
        if (submitValues[`categoryId_${i}`] && !submitValues[`categoryId_${i + 1}`]) {
          data.append('category_id', String(submitValues[`categoryId_${i}`]));
          break;
        }
      }

      // if (submitValues.categoryId) {
      //   data.append('category_id', String(submitValues.categoryId));
      // }

      if (submitValues.companyId) {
        data.append('company_id', submitValues.companyId);
      }

      if (
        fetchTypeId &&
        submitValues.productTypeId &&
        String(submitValues.productTypeId) === String(fetchTypeId)
      ) {
        typeParams?.forEach((param: { id: number; type: string }) => {
          const { id: paramId, type } = param;
          if (type === 'multiple_enum') {
            if (submitValues[`parameter${paramId}`]) {
              if (submitValues[`parameter${paramId}`].length === 0) {
                data.append(`parameter[${paramId}][]`, 'null');
              }
              submitValues[`parameter${paramId}`].forEach((value: string) => {
                data.append(`parameter[${paramId}][]`, value);
              });
            }
          } else if (
            submitValues[`parameter${paramId}`] &&
            submitValues[`parameter${paramId}`] !== ''
          ) {
            data.append(
              `parameter[${paramId}]`,
              submitValues[`parameter${paramId}`].toString()
            );
          } else {
            data.append(`parameter[${paramId}]`, 'null');
          }
        });
      }

      if (product && id && product.id?.toString() === id.toString()) {
        dispatch(
          productActions.editStockRequest({
            product_id: Number(product.id),
            body: {
              length: Number(submitValues?.length),
              width: Number(submitValues?.width),
              height: Number(submitValues?.height),
              weight: Number(submitValues?.weight),
              stockQuantity: Number(submitValues?.stockQuantity),
              barcode: submitValues?.barcode,
              expirationDateString: submitValues?.expiration_date,
              packageAmount: 0,
              goodsName: '',
            },
          })
        );
        dispatch(productActions.editRequest({ id: Number(product.id), data }));
      } else if (!id && !product) {
        submitValues?.length && data.append('length', submitValues.length);
        submitValues?.width && data.append('width', submitValues?.width);
        submitValues?.height && data.append('height', submitValues?.height);
        submitValues?.weight && data.append('weight', submitValues?.weight);
        submitValues?.barcode && data.append('barcode', submitValues?.barcode);
        submitValues?.expiration_date &&
          data.append('expirationDateString', submitValues?.expiration_date);
        images.forEach(img => data.append('photo[]', img));
        dispatch(productActions.addRequest({ data }));
      } else if (!id && product) {
        submitValues?.length && data.append('length', submitValues.length);
        submitValues?.width && data.append('width', submitValues?.width);
        submitValues?.height && data.append('height', submitValues?.height);
        submitValues?.weight && data.append('weight', submitValues?.weight);
        submitValues?.barcode && data.append('barcode', submitValues?.barcode);
        submitValues?.expiration_date &&
          data.append('expirationDateString', submitValues?.expiration_date);
        images.forEach(img => data.append('photo[]', img));
        dispatch(productActions.addRequest({ data }));
      }
    },
  });

  useEffect(() => {
    if (typeParams && typeParams.length > 0) {
      typeParams.forEach((param: ITypeParameter) => {
        if (!formik.values[`parameter${param.id}`]) {
          formik.setFieldValue(
            `parameter${param.id}`,
            param.type === 'multiple_enum' ? [] : ''
          );
        }
      });
    }
  }, [typeParams]);

  return {
    formik,
    setDescEditorState,
    setDescEditorStateTranslation,
    descEditorState,
    descEditorStateTranslation,
  };
};
