import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton, Typography, useTheme, useMediaQuery } from '@mui/material';
import GridIcon from '@mui/icons-material/GridOn';
import ListIcon from '@mui/icons-material/List';
import TabletIcon from '@mui/icons-material/TableChart';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import Select from 'react-select';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';

import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { actions as productForDaysActions } from '../../../store/ducks/product-for-days.duck';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IProduct, IProductFilter } from '../../../interfaces/product';
import { ProductsGrid } from './components';
import ProductsTableListComponent from './components/ProductsTableList';
import FilterCategoriesTree from './components/FilterCategoriesTree';
import FilterType from './components/FilterType';
import { useCustomParams } from './hooks/useCustomParams';
import { useStylesProductCatalog } from './hooks/useStyles';
import { useAxiosGet, useFormatMessage } from '../../../hooks';
import { ILocation } from '../../../interfaces/locations';
import { IProductType } from '../../../interfaces/productType';
import FilterCollections from './components/FilterCollections';
import ProductList from './components/ProductList';

const ProductCatalog: React.FC<TPropsFromRedux> = ({
  search,
  sortType,
  products,
  loading,
  page,
  perPage,
  total,
  deleteError,
  me,
  loadingMe,
  addProductLoading,
  addProductSuccess,
  addProductError,
  cartCompanyId,
  isAuthorized,
  companyGuestCart,
  catalogCategories,
  isAdmin,
  fetch,
  clearProducts,
  delProduct,
  clearDel,
  fetchMe,
  clearMe,
  addProductToCart,
  setProductGuestCart,
  fetchCatalogCategories,
  viewType,
  setViewType,
  setSearch,
  isSearching,
  productsCount,
  fetchProductsForDays,
  productsForDays,
  typeParams,
  fetchTypeById,
  productTypes,
  clearParams,
  fetchType,
  clearAddProduct,
  delSuccess,
  loadingTypes,
  cart,
  guestCart,
  setProductCountLoading,
  setProductCount,
}) => {
  const classes1 = useStylesProductCatalog();
  const { classes } = classes1;
  const intl = useIntl();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const locationHistory: any = useLocation();
  const { state: locationState }: any = locationHistory;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(560));
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [collections, loadingCollections] = useAxiosGet(`/api/shop/collections?slot1=true`);
  // const [hideId, setHideId] = useState<number | undefined>(-1);
  const [alertStockOver, setAlertStockOver] = useState(false);
  const [isAlertStock, setAlertStock] = useState(false);
  // const [isHideProduct, setIsHideProduct] = useState(false);
  const [cartProductId, setCartProductId] = useState<IProduct | null>(null);
  const [isCartAlertOpen, setCartAlertOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [categoryTypes, setCategoryTypes] = useState<IProductType[]>([]);
  const [filterData, setFilterData] = useState<any>({});
  const [priceFrom, setPriceFrom] = useState<string | number>('');
  const [priceTo, setPriceTo] = useState<string | number>('');
  const [selectedOption, setSelectedOption] = useState(
    (sortType && sortType) || 'salesVolume'
  );

  const [newTypeId, setNewType] = useState<number | null | 'disabled'>(null);

  const options = [
    { label: fm('POPULAR'), value: 'salesVolume' },
    { label: fm('CHEAP'), value: 'priceUp' },
    { label: fm('EXPENSIVE'), value: 'priceDown' },
    { label: fm('HIGH.RATING'), value: 'rating' },
  ];

  // const parseObjectParams = useMemo(() => {
  //   const { category } = objectParams;
  //   return { category };
  // }, [objectParams]);

  const clearAllFilters = useCallback(() => {
    localStorage.removeItem('category');
    localStorage.removeItem('filter');
    localStorage.removeItem('selectedTypeId');
    localStorage.removeItem('priceTo');
    localStorage.removeItem('priceFrom');
    // clearAllParam();
    clearParams();
  }, []);

  const locale = useMemo<ILocation | undefined | null>(() => {
    const localLocation = localStorage.getItem('location');

    if (localLocation) {
      try {
        const parseLocalLocation = JSON.parse(localLocation);
        return parseLocalLocation as ILocation;
      } catch (error) {
        console.error('Ошибка при парсинге JSON:', error);
      }
    }

    return undefined;
  }, []);

  const selectStyles = {
    container: (provided: any) => ({
      ...provided,
      // marginRight: 10,
      width: '200px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 999,
      display: state.selectProps.isSearchable ? provided.display : 'none',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? 'rgba(120,220,120,1)' : '',
      backgroundColor: 'white',
      // border: 'none !important',
      border: '1px solid #f5f8fa',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#369ff7' : '#fff',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      // color: '#4c4c4c',
      color: '#4c4c4c',
      fontWeight: 600,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: 600,
    }),
    dropdownIndicator: (provided: any, state: any) => {
      return {
        ...provided,
        display: state.selectProps.isSearchable ? provided.display : 'none',
      };
    },
  };

  const filter = useMemo(() => {
    const data = [];
    // eslint-disable-next-line guard-for-in
    for (const key in filterData) {
      data.push({
        parameter: Number(key),
        values: filterData[key].values?.map((el: any) => (el === '' ? 0 : el)),
      });
    }
    if (data.length) {
      return { parameters: data, price_from: priceFrom, price_to: priceTo };
    }
    return { parameters: [], price_from: priceFrom, price_to: priceTo };
  }, [filterData, priceFrom, priceTo]);

  const handleDeleteDialog = useCallback((item: IProduct) => {
    // if (item?.in_stock) {
    //   setHideId(item.id);
    //   setAlertStock(true);
    // } else {
    setDeleteId(item.id);
    setAlertOpen(true);
    setAlertStock(false);
    // }
  }, []);

  // const setParamsSearch = useCallback(() => {
  //   if (parseObjectParams.category) {
  //     setSelectedCategoryId(+parseObjectParams.category);
  //     setSearch(true);
  //   }
  //   fetch({
  //     page,
  //     perPage,
  //     filter,
  //     categoryId: +parseObjectParams?.category || selectedCategoryId || 0,
  //     sort_type: selectedOption,
  //     search: search || '',
  //   });
  //   isDisabledLoadingType && setLoadingTypeId(false);
  // }, [
  //   parseObjectParams,
  //   page,
  //   perPage,
  //   filter,
  //   selectedOption,
  //   search,
  //   isDisabledLoadingType,
  // ]);

  useEffect(() => {
    fetchMe();
    clearParams();
    fetchType();
    return () => {
      clearAllFilters();
      clearMe();
      clearProducts();
      clearDel();
      clearAddProduct();
      clearParams();
    };
  }, []);

  // const checkProductParams = useCallback(() => {
  //   const { priceFrom, priceTo } = objectParams;
  //   priceFrom?.toString().length > 0 ? setPriceFrom(priceFrom) : deleteParam('priceFrom');
  //   priceTo?.toString().length > 0 ? setPriceTo(priceTo) : deleteParam('priceTo');
  //   if (priceFrom?.toString().length > 0 || priceTo?.toString().length > 0) setSearch(true);
  //   if (typeParams) {
  //     // clearAllParam(['category', 'productType', 'priceFrom', 'priceTo']);
  //     const filters: any[] = [];
  //     const filterData: any = {};
  //     typeParams.forEach((i: ITypeParameter) => {
  //       const param = objectParams[`param${i.id!.toString()}`];
  //       if (param) {
  //         filters.push({ parameter: i.id, values: param.split(',') });
  //         filterData[i.id!] = { values: param.split(',') };
  //       }
  //     });
  //     filters.forEach((i: any) => {
  //       setParams(
  //         `param${i.parameter}`,
  //         i.values.map((el: any) => (el === '' ? 0 : el)).toString()
  //       );
  //     });
  //     filters.length > 0 && setIsCheckParams(true);
  //     filters.length > 0 && setSearch(true);
  //     setFilterData((prev: any) => ({ ...prev, ...filterData }));
  //     setUpdate(state => !state);
  //     setDisabledType(true);
  //   }
  // }, [objectParams, typeParams, clearAllParam]);

  useEffect(() => {
    fetchCatalogCategories({});
  }, [fetchCatalogCategories]);

  useEffect(() => {
    // typeParams && !isCheckParams && checkProductParams();
  }, [typeParams]);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    delProduct({ id: deleteId, page, perPage, filter, categoryId: selectedCategoryId || 0 });
  }, [deleteId, delProduct, perPage, page, filter]);

  const editAction = useCallback(
    (item: IProduct, isReviews?: boolean) => {
      navigate(`/products/edit/${item.id}`, { state: { isReviews } });
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: IProduct) => {
      navigate(`/viewproduct/${item.id}`);
    },
    [navigate]
  );

  // const handleCartDialog = useCallback(
  //   item => {
  //     // const stock = isBuyer || !me ? item.stock_info?.available_quantity : item.stock_quantity;
  //     // if (isAuthorized && (!stock || stock < 0)) {
  //     //   enqueueSnackbar(intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.ERROR' }), {
  //     //     variant: 'error',
  //     //   });
  //     // } else
  //     // if (isAuthorized && stock !== null)
  //     if (isAuthorized) {
  //       const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
  //       if (!newCart) {
  //         addProductToCart({
  //           product_id: item.id ? item?.id : 0,
  //           count: 1,
  //         });
  //       } else {
  //         setCartProductId(item);
  //         setCartAlertOpen(true);
  //       }
  //     } else if (!isAuthorized) {
  //       // const countProduct = guestCart?.items.find(i => i.product.id === item.id)?.count || 0;
  //       const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
  //       // if (!stock || countProduct === stock || countProduct > stock) {
  //       //   setAlertStockOver(true);
  //       // } else
  //       if (!newCart) {
  //         setProductGuestCart({ data: item, type: 'cart' });
  //       } else if (countProductsGuest === 0) {
  //         setProductGuestCart({ data: item, type: 'new' });
  //       } else {
  //         setCartProductId(item);
  //         setCartAlertOpen(true);
  //       }
  //     }
  //   },
  //   [
  //     addProductToCart,
  //     cartCompanyId,
  //     companyGuestCart,
  //     setProductGuestCart,
  //     guestCart,
  //     me,
  //     isBuyer,
  //   ]
  // );

  const handleCartDialog = useCallback(
    (item: any) => {
      // const stock = isBuyer || !me ? item.stock_info?.available_quantity : item.stock_quantity;
      // if (isAuthorized && (!stock || stock < 0)) {
      //   enqueueSnackbar(intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.ERROR' }), {
      //     variant: 'error',
      //   });
      // } else
      // if (isAuthorized && stock !== null)
      if (isAuthorized) {
        addProductToCart({
          product_id: item.id ? item?.id : 0,
          count: 1,
        });
      } else {
        setProductGuestCart({ data: item, type: 'cart' });
      }
    },
    [addProductToCart, cartCompanyId, companyGuestCart, setProductGuestCart]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading && cartProductId) {
      addProductToCart({
        product_id: cartProductId.id!,
        count: 1,
        newCart: true,
      });
    }
  }, [cartProductId, addProductToCart]);

  const addProductGuestCart = useCallback(() => {
    if (cartProductId) {
      setProductGuestCart({ data: cartProductId, type: 'new' });
      setCartAlertOpen(false);
    }
  }, [cartProductId, setProductGuestCart]);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      setCartAlertOpen(false);
      setCartProductId(null);
    },
  });

  useCrudSnackbar({
    success: delSuccess,
    error: deleteError,
    successMessage: intl.formatMessage({ id: 'PRODUCT.DELETED' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      setAlertOpen(false);
      clearDel();
      fetch({
        page,
        perPage,
        filter,
        categoryId: selectedCategoryId || 0,
        sort_type: selectedOption,
        locale,
      });
      fetchProductsForDays({ page, perPage });
    },
  });
  useEffect(() => {
    if (isSmallScreen) {
      setViewType('grid');
    }
  }, [isSmallScreen]);
  useEffect(() => {
    if (locationHistory?.state?.categoryId) {
      setSearch(true);
      // setParams('category', locationHistory.state.categoryId);
      setSelectedCategoryId(locationHistory.state.categoryId);
      // fetch({ page, perPage, filter, categoryId: location.state.categoryId, });
      // setParamsSearch();
      // history.replace({ ...location, state: undefined });
    } else {
      // setParamsSearch();
    }
    search && setSearch(true);
    fetchProductsForDays({ page, perPage });
    // fetchType();
  }, [sortType, search]);

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: 'error' });
      setAlertOpen(false);
      clearDel();
    }
    return () => {
      clearDel();
    };
  }, [deleteError, clearDel, enqueueSnackbar]);

  useEffect(() => {
    locationState?.filter?.price_from && setPriceFrom(locationState.filter.price_from);
    locationState?.filter?.price_to && setPriceTo(locationState.filter.price_to);
    if (locationState?.productTypeId) {
      fetchTypeById({ id: locationState.productTypeId, filters: true });
    }
    if (locationState?.filter?.parameters) {
      setFilterData(locationState.filter.parameters);
      fetch({
        page,
        perPage,
        filter: {
          parameters: locationState?.paramsArray || [],
          price_from: locationState?.filter?.price_from || '',
          price_to: locationState?.filter?.price_to || '',
        },
        categoryId: selectedCategoryId || 0,
        sort_type: selectedOption,
        locale,
      });
      setSearch(true);
    } else {
      fetch({
        page,
        perPage,
        filter,
        categoryId: selectedCategoryId || 0,
        sort_type: selectedOption,
        locale,
      });
    }
    // if (locationState?.filter) {
    //   fetch({
    //     page,
    //     perPage,
    //     filter: {
    //       parameters: [],
    //       price_from: locationState?.filter?.price_from || '',
    //       price_to: locationState?.filter?.price_to || '',
    //     },
    //     categoryId: selectedCategoryId || 0,
    //     locale,
    //   });
    //   setSearch(true);
    // }
    // navigate(location.pathname, {});
    // history.replace({ state: null });
  }, [locationState]);

  const handleSetCountCart = useCallback(
    (type: 'dec' | 'inc', count: number, product: IProduct) => {
      setProductCount({
        product_id: product.id || 0,
        count,
      });
    },
    [setProductCount]
  );

  useEffect(() => {
    // if (Object.keys(objectParams).length) {
    //   localStorage.removeItem('filter');
    //   localStorage.removeItem('selectedTypeId');
    //   const { priceFrom, priceTo } = objectParams;
    //   priceFrom?.toString().length > 0 ? setPriceFrom(priceFrom) : deleteParam('priceFrom');
    //   priceTo?.toString().length > 0 ? setPriceTo(priceTo) : deleteParam('priceTo');
    //   if (priceFrom?.toString().length > 0 || priceTo?.toString().length > 0) setSearch(true);
    // } else {
    //   const storeDataFilter = localStorage.getItem('filter');
    //   const selectedTypeId = localStorage.getItem('selectedTypeId');
    //   const localPriceTo = localStorage.getItem('priceTo');
    //   const localPriceFrom = localStorage.getItem('priceFrom');
    //   const localCategory = localStorage.getItem('category');
    //   const localCategoryParse = localCategory && JSON.parse(localCategory);
    //   const localPriceToParse = localPriceTo && JSON.parse(localPriceTo);
    //   const localPriceFromParse = localPriceFrom && JSON.parse(localPriceFrom);
    //   if (localCategoryParse) {
    //     setSelectedCategoryId(+localCategoryParse);
    //     setParams('category', localCategoryParse);
    //     setSearch(true);
    //   }
    //   if (localPriceToParse) {
    //     setPriceTo(localPriceToParse);
    //     setParams('priceTo', localPriceToParse);
    //     setSearch(true);
    //   }
    //   if (localPriceFromParse) {
    //     setPriceFrom(localPriceFromParse);
    //     setParams('priceFrom', localPriceFromParse);
    //     setSearch(true);
    //   }
    //   const selectedTypeIdParse = selectedTypeId && JSON.parse(selectedTypeId);
    //   const storeDataFilterParse = storeDataFilter && JSON.parse(storeDataFilter);
    //   const data = storeDataFilterParse || {};
    //   clearAllParam(['category', 'productType', 'priceFrom', 'priceTo']);
    //   selectedTypeIdParse && setParams('productType', selectedTypeIdParse);
    //   // eslint-disable-next-line guard-for-in
    //   for (const key in data) {
    //     data[key]?.values &&
    //       setParams(
    //         `param${key}`,
    //         data[key].values.map((el: any) => (el === '' ? 0 : el)).toString()
    //       );
    //   }
    //   setFilterData(data);
    //   setUpdate(state => !state);
    // }
  }, []);

  useEffect(() => {
    localStorage.setItem('filter', JSON.stringify(filterData));
  }, [filterData]);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'PRODUCT.CATALOG.TITLE' }),
  });
  setLayoutFooter({ show: true });

  // const filterSubmit = useCallback(() => {
  //   setSearch(true);
  //   fetch({ page, perPage, filter, categoryId: selectedCategoryId || 0, locale });
  // }, [page, perPage, filter, selectedCategoryId]);

  // const filterClear = useCallback(() => {
  //   if (filter.parameters.length === 0 && !selectedCategoryId) {
  //     setSearch(false);
  //   }
  //   setPriceFrom('');
  //   setPriceTo('');
  //   fetch({
  //     page,
  //     perPage,
  //     filter: { ...filter, price_from: '', price_to: '' },
  //     categoryId: selectedCategoryId || 0,
  //     locale,
  //   });
  // }, [page, perPage, filter, selectedCategoryId]);

  // const goToCreateProduct = useCallback(() => {
  //   if (me?.is_admin) {
  //     history.push(`/products/create`);
  //   } else {
  //     history.push(`/products/create/company/${me?.company?.id}/${me?.company?.name}`);
  //   }
  // }, [history, me]);

  const sortingProducts = useCallback(
    (value: string) => {
      fetch({
        page: 1,
        perPage,
        filter,
        search: search || '',
        categoryId: selectedCategoryId || 0,
        sort_type: value,
        locale,
      });
    },
    [search, selectedCategoryId, perPage, filter]
  );

  const resetProducts = useCallback(() => {
    fetch({ page: 1, perPage: 12, filter, categoryId: 0, sort_type: selectedOption, locale });
  }, [catalogCategories, filter]);

  const [onChangeParam] = useCustomParams();

  const resetProductTypes = useCallback(
    (categoryId?: number | null, newFilter?: IProductFilter) => {
      clearParams();
      fetch({
        page: 1,
        perPage: 12,
        filter: newFilter || filter,
        categoryId: categoryId || 0,
        sort_type: selectedOption,
        locale,
      });
    },
    [productTypes, filter]
  );

  if (loadingMe || loadingTypes || loadingCollections) {
    return <Preloader />;
  }

  return (
    <>
      <AlertDialog
        open={alertStockOver}
        message={intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.OVER' })}
        okText=''
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        handleClose={() => {
          setAlertStockOver(false);
        }}
        handleAgree={() => {
          setAlertStockOver(false);
        }}
      />
      <AlertDialog
        open={isAlertStock}
        message={intl.formatMessage({ id: 'PRODUCT.DELETE.STOCK' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.INACTIVE' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          // setIsHideProduct(false);
          setAlertStock(false);
        }}
        handleAgree={() => {
          // setIsHideProduct(true);
          setAlertStock(false);
        }}
      />
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PRODUCT.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearDel();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />

      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => (isAuthorized ? addCartProductAction() : addProductGuestCart())}
      />

      <div className={classes.container}>
        <div className={classes.filterCol}>
          <FilterCategoriesTree
            categories={catalogCategories || []}
            resetProducts={resetProducts}
            searchProducts={fetch}
            filter={filter}
            loading={loading}
            setSearch={setSearch}
            selectedCategoryId={selectedCategoryId}
            setSelectedCategoryId={setSelectedCategoryId}
            setCategoryTypes={setCategoryTypes}
            isAdmin={me?.is_admin}
          />
          <FilterType
            productTypeFilters={typeParams}
            // clear={filterClear}
            resetProductTypes={resetProductTypes}
            onChangeParam={onChangeParam}
            setSearch={setSearch}
            searchProducts={fetch}
            getFilters={fetchTypeById}
            productTypes={categoryTypes.length === 0 ? productTypes : categoryTypes}
            setFilterData={setFilterData}
            filterData={filterData}
            selectedCategoryId={selectedCategoryId}
            priceFrom={priceFrom}
            priceTo={priceTo}
            filter={filter}
            locationStateFilter={locationState?.filter}
            productTypeId={locationState?.productTypeId}
            setPriceFrom={setPriceFrom}
            setPriceTo={setPriceTo}
            setLoadingTypeId={() => {}}
            newTypeId={newTypeId}
            setNewType={setNewType}
          />
          <FilterCollections
            selectedCategoryId={selectedCategoryId}
            setSearch={setSearch}
            collections={collections || []}
            setFilterData={setFilterData}
            fetch={fetch}
            setPriceFrom={setPriceFrom}
            setPriceTo={setPriceTo}
            setNewType={setNewType}
            resetProductTypes={resetProductTypes}
            clearParams={clearParams}
          />
        </div>

        <div className={classes.productsCol}>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!products || !products.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'PRODUCT.CATALOG.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div
                  className={clsx(classes.card, {
                    [classes.revers]: isSearching,
                  })}
                >
                  {!productsForDays ||
                    (productsForDays.length !== 0 && !isSearching && (
                      <div className={classes.card}>
                        <div className={classes.productColHeader}>
                          <div className={classes.productColHeaderItem}>
                            <Typography variant='h6' className={classes.count}>
                              {intl.formatMessage({ id: 'PRODUCT.ALL.NEW_PRODUCTS' })}
                            </Typography>
                          </div>
                        </div>
                        <ProductsGrid
                          addProductLoading={addProductLoading}
                          isAuthorized={isAuthorized}
                          isAdmin={!!isAdmin}
                          me={me}
                          handleCartDialog={handleCartDialog}
                          viewAction={viewAction}
                          editAction={editAction}
                          handleDeleteDialog={handleDeleteDialog}
                          data={productsForDays}
                          page={page}
                          perPage={perPage}
                          total={total}
                          categoryId=''
                          companyId=''
                          isPaginator={false}
                          fetch={({ page, perPage }) =>
                            fetch({
                              page,
                              perPage,
                              filter,
                              categoryId: selectedCategoryId || 0,
                              locale,
                            })
                          }
                        />
                      </div>
                    ))}
                  <div>
                    <div className={classes.productColHeader}>
                      <div className={classes.productColHeaderItem}>
                        <Typography variant='h6' className={classes.count}>
                          {isSearching
                            ? `${fm('ITEMS.FOUND')} ${productsCount} `
                            : intl.formatMessage({ id: 'PRODUCT.ALL.PRODUCTS' })}
                        </Typography>
                      </div>

                      <div className={classes.productColHeaderItem}>
                        <Select
                          defaultValue={
                            options.find(item => item.value === sortType) || options[0]
                          }
                          onChange={newData => {
                            setSelectedOption(newData?.value || selectedOption);
                            sortingProducts(newData?.value || selectedOption);
                          }}
                          options={options}
                          placeholder={fm('POPULAR')}
                          styles={selectStyles}
                          noOptionsMessage={() => fm('TYPES.NOT.FOUND')}
                        />
                        {!isSmallScreen && (
                          <div style={{ marginLeft: 10 }}>
                            <IconButton
                              onClick={() => setViewType('grid')}
                              color={viewType === 'grid' ? 'primary' : 'inherit'}
                            >
                              <GridIcon />
                            </IconButton>

                            <IconButton
                              onClick={() => setViewType('list')}
                              color={viewType === 'list' ? 'primary' : 'inherit'}
                            >
                              <ListIcon />
                            </IconButton>

                            <IconButton
                              onClick={() => setViewType('table')}
                              color={viewType === 'table' ? 'primary' : 'inherit'}
                            >
                              <TabletIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                    {viewType === 'table' ? (
                      <ProductsTableListComponent
                        companyId=''
                        data={products}
                        page={page}
                        perPage={perPage}
                        total={total}
                        categoryId=''
                        fetch={({ page, perPage }) =>
                          fetch({
                            page,
                            perPage,
                            filter,
                            categoryId: selectedCategoryId || 0,
                            locale,
                          })
                        }
                        isAuthorized={isAuthorized}
                        isAdmin={!!isAdmin}
                        me={me}
                        addProductLoading={addProductLoading}
                        handleCartDialog={handleCartDialog}
                        viewAction={viewAction}
                        editAction={editAction}
                        handleDeleteDialog={handleDeleteDialog}
                      />
                    ) : (
                      <>
                        {viewType === 'list' ? (
                          <ProductList
                            addProductLoading={addProductLoading}
                            isAuthorized={isAuthorized}
                            isAdmin={!!isAdmin}
                            me={me}
                            setProductCountLoading={setProductCountLoading}
                            handleCartDialog={handleCartDialog}
                            viewAction={viewAction}
                            editAction={editAction}
                            handleDeleteDialog={handleDeleteDialog}
                            handleSetCountCart={handleSetCountCart}
                            data={products || []}
                            page={page}
                            perPage={perPage}
                            total={total}
                            cart={cart}
                            guestCart={guestCart}
                            categoryId=''
                            companyId=''
                            fetch={({ page, perPage }) =>
                              fetch({
                                page,
                                perPage,
                                filter,
                                categoryId: selectedCategoryId || 0,
                                locale,
                              })
                            }
                          />
                        ) : (
                          <ProductsGrid
                            addProductLoading={addProductLoading}
                            isSmallScreen={isSmallScreen}
                            isAuthorized={isAuthorized}
                            isAdmin={!!isAdmin}
                            me={me}
                            handleCartDialog={handleCartDialog}
                            viewAction={viewAction}
                            editAction={editAction}
                            handleDeleteDialog={handleDeleteDialog}
                            data={products}
                            page={page}
                            perPage={perPage}
                            total={total}
                            categoryId=''
                            companyId=''
                            fetch={({ page, perPage }) =>
                              fetch({
                                page,
                                perPage,
                                filter,
                                categoryId: selectedCategoryId || 0,
                                locale,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    products: state.productsCatalog.products,
    loading: state.productsCatalog.loading,
    page: state.productsCatalog.page,
    perPage: state.productsCatalog.per_page,
    total: state.productsCatalog.total,
    deleteError: state.productsCatalog.delError,
    viewType: state.productsCatalog.viewType,
    isSearching: state.productsCatalog.isSearching,
    productsCount: state.productsCatalog.total,

    catalogCategories: state.categories.catalogCategories,

    me: state.profile.me,
    loadingMe: state.profile.loading,

    productsForDays: state.productForDays.productsForDays,

    addProductLoading: state.cart.addProductLoading,
    addProductSuccess: state.cart.addProductSuccess,
    addProductError: state.cart.addProductError,
    cartCompanyId: state.cart.companyId,
    isAuthorized: state.auth.user != null,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    isAdmin: state.auth.user?.is_admin,
    typeParams: state.productTypes.typeParams,
    productTypes: state.productTypes.productTypes,
    delSuccess: state.productsCatalog.delSuccess,
    loadingTypes: state.productTypes.loading,
    sortType: state.productsCatalog.sortType,
    search: state.productsCatalog.search,

    cart: state.cart.cart,
    guestCart: state.cart.guestCart,
    setProductCountLoading: state.cart.setProductCountLoading,
  }),
  {
    fetch: productCatalogActions.fetchRequest,
    clearProducts: productCatalogActions.clearProducts,
    delProduct: productCatalogActions.delRequest,
    clearDel: productCatalogActions.clearDel,
    setViewType: productCatalogActions.setViewType,
    setSearch: productCatalogActions.setSearch,
    setProductCount: cartActions.setProductCountRequest,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    addProductToCart: cartActions.addProductRequest,
    setProductGuestCart: cartActions.setProductGuestCart,

    fetchCatalogCategories: categoriesActions.fetchCatalogCategories,

    fetchProductsForDays: productForDaysActions.fetchRequest,
    fetchTypeById: productTypesActions.fetchParamsByIdRequest,
    clearParams: productTypesActions.clearParams,
    fetchType: productTypesActions.fetchRequest,

    clearAddProduct: cartActions.clearAddProduct,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductCatalog);
