import React from 'react';
import {
  Typography,
  Link,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  TableFooter,
  Tooltip,
} from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl, IntlShape } from 'react-intl';
import ProductListIcon from '@mui/icons-material/FormatListNumbered';
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';

import TablePaginator, {
  IProps as PaginatorProps,
} from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import StatusIndicator from '../../../../components/styledComponents/StatusIndicator';

import { ICategoryTable, ICategoryItem } from '../interfaces';

import homeStyles from '../../../../constants/homeStyles';
import { API_DOMAIN } from '../../../../constants';

interface ISortableCategoryItem {
  item: ICategoryItem;
  handleDeleteDialog: any;
  productListAction: any;
  editAction: any;
  classes?: any;
  intl: IntlShape;
  is_admin: boolean;
  parentId?: string;
  isManager: boolean;
  lang: string;
  viewAction: any;
}

const TableBodySortable: any = SortableContainer(
  ({ children }: { children: React.ReactNode }) => <TableBody>{children}</TableBody>
);

const DragHandle = SortableHandle(() => <span style={{ cursor: 'move' }}>::::</span>);

const CategoryRow: any = SortableElement(
  ({
    item,
    handleDeleteDialog,
    classes,
    productListAction,
    editAction,
    intl,
    is_admin,
    parentId,
    isManager,
    lang,
    viewAction,
  }: ISortableCategoryItem) => {
    // @ts-ignore
    const nameLang = lang === 'ru' ? item.name : item.names[`name_${lang}`];
    // @ts-ignore
    const description = lang === 'ru' ? item.description : item.names[`description_${lang}`];

    return (
      <TableRow key={item.id}>
        <TableCell>{is_admin && <DragHandle />}</TableCell>
        <TableCell component='th' scope='row'>
          {item.id || '-'}
        </TableCell>
        <TableCell component='th' scope='row'>
          {(item.avatar?.small || item.avatar?.path) && (
            <img
              src={`${API_DOMAIN}/${item.avatar?.small || item.avatar?.path}`}
              className={classes.img}
              alt={item.name}
            />
          )}
        </TableCell>
        <TableCell className={!nameLang ? classes.fontNoLang : classes.cell}>
          <Link
            component={RouterLink}
            to={`/categories/list/${item.id}/${parentId || ''}`}
            className={classes.buttons}
          >
            {`${nameLang || item.name} (${item.count_children})`}
          </Link>
        </TableCell>
        <TableCell className={!description ? classes.fontNoLang : classes.cell}>
          {description || item.description || '-'}
        </TableCell>
        <TableCell>
          <StatusIndicator isActive={item.active} />
        </TableCell>
        <TableCell>
          <StatusIndicator isActive={item.favorite} />
        </TableCell>
        <TableCell align='right'>
          <div>
            <Tooltip
              title={intl.formatMessage({
                id: 'CATEGORY.VIEW',
              })}
            >
              <IconButton onClick={() => viewAction(item)}>
                <VisibilityIcon color='primary' />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={intl.formatMessage({
                id: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
              })}
            >
              <IconButton
                onClick={() => productListAction(item)}
                className={classes.labelIcon}
              >
                <ProductListIcon color='primary' />
              </IconButton>
            </Tooltip>
            {is_admin && (
              <>
                <Tooltip title={intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.EDIT' })}>
                  <IconButton onClick={() => editAction(item)}>
                    <EditIcon color='primary' />
                  </IconButton>
                </Tooltip>
                {!isManager && (
                  <Tooltip
                    title={intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.DELETE' })}
                  >
                    <IconButton
                      onClick={() => {
                        handleDeleteDialog(item.id);
                      }}
                    >
                      <DeleteIcon color='error' />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }
);

const CategoriesTable: React.FC<ICategoryTable & PaginatorProps> = ({
  categories,
  handleDeleteDialog,
  editAction,
  classes,
  parentId,
  page,
  perPage,
  total,
  fetch,
  productListAction,
  setPositions,
  is_admin,
  isManager,
  lang,
  viewAction,
}) => {
  const intl = useIntl();
  const homeClasses = homeStyles();

  return (
    <Row>
      <Col>
        {categories.length === 0 ? (
          <Typography className={classes.empty} component='h5' variant='h5'>
            {parentId
              ? intl.formatMessage({ id: 'CATEGORIES.EMPTY_SUBCATEGORIES' })
              : intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
          </Typography>
        ) : (
          <>
            <Card className={classes.card}>
              <CardContent className={homeClasses.classes.tableContainer}>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <SHeadTableCell />
                      <SHeadTableCell>
                        {intl.formatMessage({ id: 'CATEGORIES.TABLE.ID' })}
                      </SHeadTableCell>
                      <SHeadTableCell>
                        {intl.formatMessage({ id: 'CATEGORY.ICON' })}
                      </SHeadTableCell>
                      <SHeadTableCell>
                        {intl.formatMessage({ id: 'CATEGORIES.TABLE.NAME' })}
                      </SHeadTableCell>
                      <SHeadTableCell>
                        {intl.formatMessage({ id: 'CATEGORIES.TABLE.DESCRIPTION' })}
                      </SHeadTableCell>
                      <SHeadTableCell>
                        {intl.formatMessage({ id: 'CATEGORIES.TABLE.ACTIVE' })}
                      </SHeadTableCell>
                      <SHeadTableCell>{intl.formatMessage({ id: 'FAVORITE' })}</SHeadTableCell>
                      <SHeadTableCell align='right'>
                        {intl.formatMessage({ id: 'CATEGORIES.TABLE.ACTIONS' })}
                      </SHeadTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBodySortable
                    useDragHandle
                    onSortEnd={({ oldIndex, newIndex }: any) => {
                      if (oldIndex !== newIndex) {
                        const sortCategories = arrayMove(categories, oldIndex, newIndex);
                        const positions = sortCategories.reduce(
                          (accumulator, item, index) =>
                            accumulator + (index === 0 ? '' : ',') + item.id,
                          ''
                        );
                        if (setPositions) {
                          setPositions({ positions, categories: sortCategories });
                        }
                      }
                    }}
                  >
                    {categories.map((item, index) => {
                      if (!item.active && !is_admin) return null;
                      return (
                        <CategoryRow
                          key={item.id}
                          index={index}
                          item={item}
                          handleDeleteDialog={handleDeleteDialog}
                          editAction={editAction}
                          productListAction={productListAction}
                          intl={intl}
                          classes={classes}
                          is_admin={is_admin}
                          isManager={isManager}
                          lang={lang}
                          viewAction={viewAction}
                        />
                      )
                    })}

                  </TableBodySortable>
                  <TableFooter>
                    <TableRow>
                      <TablePaginator
                        parentId={parentId}
                        page={page}
                        realPerPage={categories.length}
                        perPage={perPage}
                        total={total}
                        fetchRows={fetch}
                        label={intl.formatMessage({ id: 'CATEGORIES.TABLE.PERPAGE' })}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </CardContent>
            </Card>
          </>
        )}
      </Col>
    </Row>
  );
};

export default CategoriesTable;
