import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMyFavorites } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';

export const useGetFavorites: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchFavorites = useCallback(async () => {
    setLoading(true);
    await getMyFavorites()
      .then(res => {        
        setProducts(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return [fetchFavorites, loading, products];
};
